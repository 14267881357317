import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const ServiceSidebar = () => {
  const query = graphql`
    query {
      allContentfulServiceCategory(sort: { fields: createdAt }) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)
  const services = data.allContentfulServiceCategory.edges
  return (
    <>
      {services.map(service => (
        <li id={service.node.id}>
          <Link to={service.node.slug}>{service.node.name}</Link>
        </li>
      ))}
    </>
  )
}

export default ServiceSidebar
