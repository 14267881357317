import React from "react"
import { Link } from "gatsby"

const ServiceTypesSideBar = ({ data }) => {
  const serviceTypes = data.allContentfulSingleService.edges

  return (
    <>
      {serviceTypes.map((serviceType, index) => (
        <li key={serviceType.node.id}>
          <Link to={serviceType.node.slug}>{serviceType.node.name}</Link>
        </li>
      ))}
    </>
  )
}

export default ServiceTypesSideBar
