import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceSidebar from "../components/servicesidebar.component"
import ServiceTypesSideBar from "../components/servicetypessidebar.component"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
  query($slug: String!, $id: String!) {
    contentfulSingleService(slug: { eq: $slug }) {
      name
      seoDescription
      seoKeywords
      seoTitle
      id
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        json
      }
      category {
        name
        shortName
      }
    }
    allContentfulSingleService(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`

const SingleService = ({ data }) => {
  const service = data.contentfulSingleService
  return (
    <Layout>
      <SEO
        title={service.seoTitle || ""}
        description={service.seoDescription || ""}
        keywords={service.seoKeywords || ""}
      />
      <section className="inner-intro bg-img-single-service light-color overlay-before parallax-background">
        <div className="container">
          <div className="row title">
            <div className="title_row">
              <h2 data-title={service.category.shortName}>
                <span>{service.category.shortName}</span>
              </h2>
              <div className="page-breadcrumb">
                <Link to="/">Home </Link>/{" "}
                <Link to="/services/">Services </Link>/{" "}
                <span>{service.category.shortName}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="services-section" className="pt-80 pt-xs-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="full-pic">
                <figure>
                  {service.image && (
                    <Img fluid={service.image.fluid} alt={service.name} />
                  )}
                </figure>
              </div>
              <div className="text-box mt-40 mb-40">
                {/* <div className="box-title mb-20">
                  <h1>CNC Router Cutting-2D/3D and engraving work in Dubai</h1>
                </div> */}
                <div className="text-content">
                  {documentToReactComponents(service.content.json)}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="single-sidebar-widget">
                <div className="sec-title">
                  <h2>
                    {service.category.shortName}
                    <span> Types</span>
                  </h2>
                </div>
                <div className="special-links">
                  <ul>
                    <ServiceTypesSideBar data={data} />
                  </ul>
                </div>
              </div>
              <div className="single-sidebar-widget">
                <div className="sec-title">
                  <h2>
                    Our<span> Services</span>
                  </h2>
                </div>
                <div className="special-links">
                  <ul>
                    <ServiceSidebar />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SingleService
